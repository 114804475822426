import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ExampleBox = makeShortcode("ExampleBox");
const PrimaryButtonExample = makeShortcode("PrimaryButtonExample");
const SecondaryButtonExample = makeShortcode("SecondaryButtonExample");
const TertiaryButtonExample = makeShortcode("TertiaryButtonExample");
const IconTextButtonExample = makeShortcode("IconTextButtonExample");
const IconButtonExample = makeShortcode("IconButtonExample");
const FullWidthButtonExample = makeShortcode("FullWidthButtonExample");
const CompactButtonExample = makeShortcode("CompactButtonExample");
const LightButtonExample = makeShortcode("LightButtonExample");
const DarkButtonExample = makeShortcode("DarkButtonExample");
const DisabledButtonsExample = makeShortcode("DisabledButtonsExample");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const FbButton = makeShortcode("FbButton");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-buttons"
            }}>{`How to use buttons`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#styles-of-buttons"
            }}>{`Styles of buttons`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-buttons"
            }}>{`Types of buttons`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#button-schemes"
            }}>{`Button schemes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#button-disabled-states"
            }}>{`Button disabled states`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-buttons",
        "aria-label": "how to use buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use buttons`}</h2>
    <p>{`Buttons can be used in dialog boxes, forms, cards or toolbars.`}</p>
    <p>{`When using buttons:`}</p>
    <ul>
      <li parentName="ul">{`Use the button component if the button will trigger an action on the current page`}</li>
      <li parentName="ul">{`Position buttons in locations that are easy to find and consistent`}</li>
      <li parentName="ul">{`Write button labels that tell people what will happen when the button is used`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "styles-of-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styles-of-buttons",
        "aria-label": "styles of buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Styles of buttons`}</h2>
    <p>{`Buttons come in 3 styles for 3 levels of emphasis. Which one you use depends on the importance of the action.`}</p>
    <h3>{`Primary button`}</h3>
    <p>{`Use a primary button to give a high level of emphasis to an action. This should be the main call to action on a screen. Only include one primary button per screen.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <PrimaryButtonExample mdxType="PrimaryButtonExample" />
    </ExampleBox>
    <h3>{`Secondary button`}</h3>
    <p>{`Use a secondary button to give a medium level of emphasis to an action. These should be secondary calls to action on a screen. Only use a secondary button when the page already includes a primary button.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <SecondaryButtonExample mdxType="SecondaryButtonExample" />
    </ExampleBox>
    <h3>{`Tertiary button`}</h3>
    <p>{`Use a tertiary button to give a low level of emphasis to an action. These should be sub-tasks or less important calls to action. Only use a tertiary button when a screen already includes both primary and secondary buttons.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <TertiaryButtonExample mdxType="TertiaryButtonExample" />
    </ExampleBox>
    <h2 {...{
      "id": "types-of-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-buttons",
        "aria-label": "types of buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of buttons`}</h2>
    <p>{`Buttons also come in different types, with and without icons.`}</p>
    <h3>{`Text button`}</h3>
    <p>{`A text button is the standard type of button.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <PrimaryButtonExample mdxType="PrimaryButtonExample" />
    </ExampleBox>
    <h3>{`Icon and text button`}</h3>
    <p>{`Use a text button along with an icon to help people understand what the button does. The icon should reflect the meaning of the text.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <IconTextButtonExample mdxType="IconTextButtonExample" />
    </ExampleBox>
    <h3>{`Icon button`}</h3>
    <p>{`Only use an icon button when the context makes it completely clear what the button's for. Include a text label too, wherever possible. Read the `}<a parentName="p" {...{
        "href": "/accessibility/accessibility-standards-for-web/"
      }}>{`accessibility section`}</a>{` for more guidance on using icon buttons.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <IconButtonExample mdxType="IconButtonExample" />
    </ExampleBox>
    <h3>{`Full Width Button`}</h3>
    <p>{`Use a full width button when you want to give a button more prominence on the page. This is useful for buttons that are the main call to action on a page`}</p>
    <ExampleBox mdxType="ExampleBox">
  <FullWidthButtonExample mdxType="FullWidthButtonExample" />
    </ExampleBox>
    <h3>{`Compact Button`}</h3>
    <p>{`The compact button should only be used when absolutely necessary, for example, when space is at a premium, like on a table. This is because it is important for accessibility that buttons have at least a 48px touch target.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <CompactButtonExample mdxType="CompactButtonExample" />
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "button-schemes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#button-schemes",
        "aria-label": "button schemes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Button schemes`}</h2>
    <p>{`Buttons also come in different schemes for different contexts.`}</p>
    <h3>{`Brand`}</h3>
    <p>{`Use brand schemed buttons to highlight the strongest calls to action on the page. They're good for positive experiences and journeys, such as completing a purchase. They use a primary colour from the brand colour palette. These can be viewed using the theme displayer at the bottom of the page.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <PrimaryButtonExample mdxType="PrimaryButtonExample" />
    </ExampleBox>
    <h3>{`Light`}</h3>
    <p>{`Light and dark schemed buttons can improve contrast against coloured backgrounds. They can also be used for lower priority calls to action.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <LightButtonExample mdxType="LightButtonExample" />
    </ExampleBox>
    <h3>{`Dark`}</h3>
    <p>{`Light and dark schemed buttons can improve contrast against coloured backgrounds. They can also be used for lower priority calls to action.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <DarkButtonExample mdxType="DarkButtonExample" />
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "button-disabled-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#button-disabled-states",
        "aria-label": "button disabled states permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Button disabled states`}</h2>
    <ExampleBox mdxType="ExampleBox">
  <DisabledButtonsExample mdxType="DisabledButtonsExample" />
    </ExampleBox>
    <p>{`Disabled states indicate an action that can’t be taken yet. Don’t use disabled buttons if people can’t take steps to enable it.`}</p>
    <p>{`It shouldn’t be necessary to read the text on a disabled state element to complete a task, as people with low vision might find it impossible to read the label of a disabled state button.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <p>{`Button labels should make it easy to understand what will happen if the button is used. People should be able to understand what the button will do, even if they haven't read any other content on the page.`}</p>
    <p>{`Lead with a strong, actionable verb paired with a noun.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Save changes
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Save
    </FbButton>
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      See details
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Details
    </FbButton>
  </Dont>
    </Guideline>
    <p>{`Make sure your button label fits on one line.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Save changes
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Save changes and
      <br />
      continue
    </FbButton>
  </Dont>
    </Guideline>
    <p>{`Start with a capital letter and use sentence case, but don’t add full stops.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Go to trolley
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      GO TO TROLLEY.
    </FbButton>
  </Dont>
    </Guideline>
    <p>{`Avoid using ‘my’ or ‘your’ in button labels.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Log in to account
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Log in to your account
    </FbButton>
  </Dont>
    </Guideline>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Track delivery
    </FbButton>
  </Do>
  <Dont center mdxType="Dont">
    <FbButton variant="primary" isRounded mdxType="FbButton">
      Track my delivery
    </FbButton>
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using buttons or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      